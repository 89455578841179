import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const Construct = () => (
  <Layout>
    <SEO title="納入実績" />
    <div>
      <section className="w-full lg:mr-0 lg:ml-auto lg:w-2/3">
        <div className="pt-32">
          <h1 className="font-mincho text-3xl text-primary">駿河湾沼津SA</h1>
        </div>
        <div className="mt-8 grid w-full grid-cols-1 gap-2 md:grid-cols-2 md:gap-4 ">
          <StaticImage
            src="../../images/works/suruga/1.jpg"
            alt="駿河湾沼津1"
            className="w-full"
            quality={100}
          />
          <StaticImage
            src="../../images/works/suruga/2.jpg"
            alt="駿河湾沼津SA2"
            className="w-full"
            quality={100}
          />
          <StaticImage
            src="../../images/works/suruga/3.jpg"
            alt="駿河湾沼津SA3"
            className="w-full"
            quality={100}
          />
        </div>
      </section>
    </div>
  </Layout>
)

export default Construct
